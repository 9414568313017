import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const secretKey = "Henrich8ktader_"; // Replace with a strong key

export default function useLoginStatus() {
  const [isLoggedIn, setLogin] = useState(false);

  // Function to decrypt data from localStorage
  const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      let getLoginStatus = localStorage.getItem("isLoggedIn");
      if (getLoginStatus) {
        getLoginStatus = decryptData(getLoginStatus);
        setLogin(getLoginStatus === "true");
      } else {
        setLogin(false);
      }
    };

    // Check initial login status
    handleStorageChange();

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return isLoggedIn;
}
