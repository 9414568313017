import React, { useState, useRef, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "./navigationbar.css";
import { NavigationItems, NavigationButtons } from "../../components";
import { NavButtons, NavItems } from "../../data/DataNavbar";
import useLoginStatus from "../../hooks/loginCheck";
import useWindowDimensions from "../../hooks/windowWidth";
import DarkMode from "../darkmode/Darkmode";
import Icon from "../../helpers/Icon";
const logoDark = require("../../images/jk_logo_black.png");
const logoLight = require("../../images/jk_logo_white.png");

function NavigationBar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [menuPageTitle, setMenuPageTitle] = useState("");
  const [navigationitems] = useState(NavItems);
  const [navigationbuttons] = useState(NavButtons);
  const [logo, setLogo] = useState(logoDark);
  const ref = useRef(null);
  const iconContainerRef = useRef();
  const navbarMenuContainerLinks = useRef(null);
  const navbarMenu = useRef(null)
  const location = useLocation();
  const pathname = location.pathname;
  const pagename = pathname.replace("/", "");
  const isLoggedIn = useLoginStatus();
  const [isMobile, setIsMobile] = useState(false);
  const screenSize = useWindowDimensions();
  const deviceWidth = screenSize.width;
  const tapAnimation = { scale: 0.95 };

  useEffect(() => {
    if (deviceWidth <= 1140) {
      setIsMobile(true);
    }
    return () => {
      setIsMobile(false);
    };
  }, [deviceWidth]);

  function scrollToTop() {
    if (pathname === "/" && scrolled) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  function handleLogo() {
    if (localStorage.getItem("theme") === "dark") {
      setLogo(logoLight);
    } else setLogo(logoDark);
  }

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 40) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    switch (pagename) {
      case "termin":
        setMenuPageTitle("Termin");
        break;
      case "about":
        setMenuPageTitle("Über mich");
        break;
      case "services":
        setMenuPageTitle("Services");
        break;
      case "projects":
        setMenuPageTitle("Galerie");
        break;
      case "contact":
        setMenuPageTitle("Kontakt");
        break;
      case "customization":
        setMenuPageTitle("Anpassung");
        break;
      case "legal":
        setMenuPageTitle("Rechtliches");
        break;
      case "test":
        setMenuPageTitle("Test");
        break;
      default:
        setMenuPageTitle("Home");
    }
  }, [pagename]);

  const changeToggleMenu = (e) => {
    e.stopPropagation();
    setToggleMenu(!toggleMenu);
  };

  const handleOutsideClick = (e) => {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      iconContainerRef.current &&
      !iconContainerRef.current.contains(e.target) &&
      navbarMenuContainerLinks.current &&
      !navbarMenuContainerLinks.current.contains(e.target) &&
      navbarMenu.current &&
      !navbarMenu.current.contains(e.target)
    ) {
      setToggleMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("storage", handleLogo);
    handleLogo();

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      window.removeEventListener("storage", handleLogo);
    };
  }, []);

  return (
    <>
      {isLoggedIn && (
        <motion.div className="navbar" id="navbar">
          <div className="navbar-links">
            <motion.div
              whileTap={tapAnimation}
              className="navbar-links_logo"
              onClick={scrollToTop}
            >
              <NavLink to="/">
                <img id="main-logo" src={logo} alt="Logo" />
              </NavLink>
            </motion.div>
            {isMobile && (
              <span
                className={[
                  "navbar-current-page",
                  "hidden-element",
                  scrolled ? "shown-element" : "",
                ].join(" ")}
              >
                {menuPageTitle}
              </span>
            )}
            <div className="navbar-links_container">
              <NavigationItems
                navigationitems={navigationitems}
                setToggleMenu={setToggleMenu}
              />
            </div>
          </div>
          <div className="navbar-sign">
            <NavigationButtons navigationbuttons={navigationbuttons} />
            <DarkMode />
          </div>
          <div className="navbar-menu">
            <motion.div
              whileTap={tapAnimation}
              className="navbar-menu__icon-container"
            >
              <NavLink to="/customization">
                <Icon iconFa="fas" iconName="fa-sliders" />
              </NavLink>
            </motion.div>
            <motion.div
              ref={navbarMenu}
              whileTap={tapAnimation}
              className="navbar-menu__icon-container"
            >
              <DarkMode />
            </motion.div>
            <motion.div
              whileTap={tapAnimation}
              className="navbar-menu__icon-container"
              onClick={(e) => changeToggleMenu(e)}
              ref={iconContainerRef}
            >
              <AnimatePresence initial={false} mode="wait">
                {toggleMenu ? (
                  <motion.div
                    key="xmark"
                    initial={{ rotate: 45 }}
                    animate={{ rotate: 90 }}
                    exit={{ rotate: 45 }}
                    transition={{ duration: 0.1 }}
                  >
                    <Icon iconFa="fas" iconName="xmark" />
                  </motion.div>
                ) : (
                  <motion.div
                    key="bars"
                    initial={{ rotate: 45 }}
                    animate={{ rotate: 0 }}
                    exit={{ rotate: 45 }}
                    transition={{ duration: 0.1 }}
                  >
                    <Icon iconFa="fas" iconName="bars" />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </div>
          <AnimatePresence>
            {toggleMenu && (
              <>
                <motion.div
                  className="navbar-menu_container-overlay"
                  initial={{ opacity: 0, y: -150, scale: 1 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{
                    opacity: 0.5,
                    y: -150,
                    scale: 1,
                  }}
                  transition={{ duration: 0.25 }}
                >
                  <motion.div
                    className="navbar-menu_container"
                    id="navbar-menu_container"
                    ref={ref}
                  >
                    <img id="main-logo" src={logo} alt="Logo" />
                    <div
                      className="navbar-menu_container-links"
                    >
                      <NavigationItems
                        navigationitems={navigationitems}
                        setToggleMenu={setToggleMenu}
                      />

                      <div
                        ref={navbarMenuContainerLinks}
                        className="navbar-menu_container-links-sign"
                      >
                        <NavigationButtons
                          navigationbuttons={navigationbuttons}
                        />
                        <DarkMode />
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </>
  );
}

export default NavigationBar;
