import { React, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useInView, motion } from "framer-motion";
import "./cta.css";

function Cta(props) {
  const cta = props.cta;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const tapAnimation = { scale: 0.95 };
  

  return (
    <>
      {cta.map((cta) => (
        <div
          key={cta.id}
          style={{
            transform: isInView ? "none" : "translateY(100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
          className={[cta.className].join(" ")}
          ref={ref}
        >
          <div className={cta.containerClassName}>
            {cta.headline && <h3>{cta.headline}</h3>}
            {cta.description && <p>{cta.description}</p>}
            {cta.buttonText && (
              <motion.div whileTap={tapAnimation}>
                <NavLink to={cta.buttonLink} className={cta.buttonClass}>
                {cta.buttonText}
              </NavLink>
              </motion.div>
            )}
            {cta.buttonTextExt && (
              <a
                rel="noreferrer"
                target="_blank"
                href={cta.buttonLinkExt}
                className={cta.buttonClass}
              >
                {cta.buttonTextExt}
              </a>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default Cta;
