export const NavItems = [
  {
    link: '/',
    title: 'Home',
   iconFa: '',
    iconName: '',
    id: 1,
    transitionDelay: 600,
  },
  {
    link: '/about',
    title: 'Über mich',
   iconFa: '',
    iconName: '',
    id: 3,
    transitionDelay: 500,
  },
  {
    link: '/services',
    title: 'Services',
   iconFa: '',
    iconName: '',
    id: 4,
    transitionDelay: 450,
  },
  {
    link: '/projects',
    title: 'Showcase',
   iconFa: '',
    iconName: '',
    id: 5,
    transitionDelay: 400,
  },
  {
    link: '/contact',
    title: 'Kontakt',
   iconFa: '',
    iconName: '',
    id: 6,
    transitionDelay: 350,
  },
  {
    link: '/customization',
    title: 'Anpassung',
   iconFa: '',
    iconName: '',
    id: 7,
    transitionDelay: 300,
    
  },
  {
    link: '/legal',
    title: 'Rechtliches',
    iconFa: '',
    iconName: '',
    id: 8,
    transitionDelay: 250,
  },
]

export const NavButtons = [
  {
    link: 'tel:+01603465558',
    title: '',
    id: 1,
    iconFa: 'fas',
    iconName: 'fa-phone',
    className: 'button-phone',
  },
  {
    link: 'mailto:info@joshuakrieg.com',
    title: '',
    iconFa: 'fas',
    iconName: 'fa-envelope',
    id: 2,
    className: '',
  },
  {
    id: 3,
    title: '',
    iconFa: 'fas',
    iconName: 'fa-sliders',
    internalLink: '/customization'
  }
]