import React, { useRef, useEffect, useState } from "react";
import "./zoomin.css";
import { motion, useScroll, useTransform, useMotionValue } from "framer-motion";

const ZoomIn = (props) => {
  const zoomInData = props.zoomin;
  const duplicatedImages = [...zoomInData, ...zoomInData, ...zoomInData];
  const [currentIndex, setCurrentIndex] = useState(0); // Start from the second set of images
  const galleryRef = useRef(null);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    scrollToIndex(index);
  };

  const scrollToIndex = (index) => {
    if (galleryRef.current) {
      const galleryWidth = galleryRef.current.offsetWidth;
      const imageElement =
        galleryRef.current.querySelectorAll(".fullscreen-image")[index];
      if (imageElement) {
        const imageWidth = imageElement.offsetWidth;
        const centerOffset = (galleryWidth - imageWidth) / 2;
        const scrollPosition = imageWidth * index - centerOffset;

        galleryRef.current.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (galleryRef.current) {
      const initialPosition = calculateScrollPosition(currentIndex);
      galleryRef.current.scrollLeft = initialPosition;
    }
  }, [currentIndex]);

  const calculateScrollPosition = (index) => {
    if (galleryRef.current) {
      const galleryWidth = galleryRef.current.offsetWidth;
      const imageElement =
        galleryRef.current.querySelectorAll(".fullscreen-image")[index];
      if (imageElement) {
        const imageWidth = imageElement.offsetWidth;
        const centerOffset = (galleryWidth - imageWidth) / 2;
        return imageWidth * index - centerOffset;
      }
    }
    return 0;
  };

  const getDisplayedImages = () => {
    const totalImages = duplicatedImages.length;
    const displayedImages = [];

    for (let i = -2; i <= 2; i++) {
      let index = (currentIndex + i) % totalImages;
      if (index < 0) index += totalImages; // Ensure positive index for negative values
      displayedImages.push(duplicatedImages[index]);
    }

    return displayedImages;
  };

  const { scrollYProgress } = useScroll({
    target: galleryRef,
    offset: ["start end", "end start"],
  });
  const opacity = useTransform(
    scrollYProgress,
    [0, 0.65, 0.7, 1],
    [1, 1, 0, 0]
  );
  const zIndex = useTransform(
    scrollYProgress,
    [0, 0.65, 0.7, 1],
    [1, 1, 0, 0])
  const FullScreenImage = ({ url, id }) => {
    const imageRef = useRef(null);
    const { scrollYProgress } = useScroll({
      target: imageRef,
      offset: ["end end", "end start"],
    });

    const scaleValue = useMotionValue(1);
    const scale = useTransform(scaleValue, [0, 0.25, 1], [5, 1, 1]);
    const zIndex = useTransform(scaleValue, [0, 0.5, 1], ["2", "2", "1"]);
    const translateY = useTransform(
      scaleValue,
      [0, 0.15, 0.40, 0.85, 1],
      ["-50vh", "-20vh", "0vh", "0vh", "0vh"],
      { clamp: false }
    );

    useEffect(() => {
      if (id === currentIndex) {
        scaleValue.set(0);
        const unsubscribe = scrollYProgress.on("change",(latest) => {
          scaleValue.set(latest);
        });
        return () => unsubscribe();
      } else {
        scaleValue.set(1);
      }
    }, [scrollYProgress, scaleValue, id]);

    return (
      <motion.div
        alt=""
        className="fullscreen-image"
        ref={imageRef}
        style={{
          backgroundImage: `url(${url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "calc(var(--button-border-radius) / 2)",
          scale,
          translateY,
          zIndex,
        }}
        onClick={() => handleImageClick(id)}
      ></motion.div>
    );
  };

  return (
    <div className="zoomin-wrapper" ref={galleryRef}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          gap: "1rem",
          overflowX: "clip",
          overflowY: "visible",
        }}
      >
        {getDisplayedImages().map((zoomInData) => (
          <div key={zoomInData.id} className="zoomin-container">
            <FullScreenImage url={zoomInData.url} id={zoomInData.id} />
            <motion.div
              style={{
                position: "absolute",
                inset: "0",
                backgroundColor: "var(--secondary-color)",
                opacity,
                zIndex
              }}
            ></motion.div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ZoomIn;
