import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./highlights.css";
import Icon from "../../helpers/Icon"; // Import the custom Icon component

function Highlights(props) {
  const { highlights, isBookable } = props;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const navigate = useNavigate();

  const handleHighlightClick = (serviceId) => {
    if (isBookable && serviceId) {
      navigate(`/termin?serviceId=${serviceId}`);
    }
  };

  return (
    <div ref={ref} className="highlights">
      {highlights.map((highlight) => (
        <div
          key={highlight.id}
          style={{
            transform: isInView ? "none" : "translateY(100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
          className={[highlight.className, highlight.highlight && "highlight-bookable"].join(" ")}
          onClick={() => handleHighlightClick(highlight.serviceId)}
        >
          <div className={highlight.containerClassName}>
            {highlight.iconName && (
              <Icon iconFa={highlight.iconFa} iconName={highlight.iconName} />
            )}
            {highlight.title && <h4>{highlight.title}</h4>}
            {highlight.description && <p>{highlight.description}</p>}
            {highlight.highlight && (
              <p className="highlight-flag">{highlight.highlight}</p>
            )}
            {highlight.price && (
              <p className="highlight-price">{highlight.price}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Highlights;
