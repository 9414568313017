import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Icon from "../../helpers/Icon"; // Import the custom Icon component

function NavigationItems({ navigationitems, setToggleMenu }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [navClicked, setNavClicked] = useState(false);
  const tapAnimation = { scale: 0.95 };

  const handleNavClick = (link) => {
    setNavClicked(true);
    navigate(link);
  };

  useEffect(() => {
    if (navClicked) {
      setToggleMenu(false);
      setNavClicked(false);
    }
  }, [location, navClicked, setToggleMenu]);

  return (
    <>
      {navigationitems.map((navigationitem) => (
        <motion.p whileTap={tapAnimation} key={navigationitem.id}>
          <NavLink
            key={navigationitem.id}
            to={navigationitem.link}
            className={({ isActive }) => (isActive ? "active" : "inactive")}
            onClick={() => handleNavClick(navigationitem.link)}
          >
            {navigationitem.iconFa && navigationitem.iconName && (
              <Icon iconFa={navigationitem.iconFa} iconName={navigationitem.iconName} />
            )}
            <span>{navigationitem.title}</span>
          </NavLink>
        </motion.p>
      ))}
    </>
  );
}

export default NavigationItems;
