import React, { useRef, useEffect, useState, useMemo } from "react";
import "./fadetext.css";
import {
  motion,
  useScroll,
  useTransform,
  useInView,
  AnimatePresence,
} from "framer-motion";

const FadeText = ({ text, description }) => {
  const containerRef = useRef(null);
  const [showDescription, setShowDescription] = useState(false);
  const isInView = useInView(containerRef, { once: true });
  const { scrollYProgress } = useScroll({ target: containerRef });
  const opacity = useTransform(scrollYProgress, [0, 0.75, 1], [0, 1, 1]);

  // Use useMemo to derive words only when text changes
  const words = useMemo(() => text.split(" "), [text]);
  const [opacities, setOpacities] = useState(Array(words.length).fill(0.1));

  useEffect(() => {
    const updateOpacities = () => {
      const newOpacities = words.map((_, index) => {
        const start = index / (words.length - 1); // Relative position of the word
        const scrollProgress = scrollYProgress.get();
        if (scrollProgress > .99) {
          setShowDescription(true)
        } else setShowDescription(false)
        // Adjust opacity logic
        if (scrollProgress > start) {
          return 1; // Fully visible
        } else if (scrollProgress === 0) {
          return 0; // Partially visible
        } else if (scrollProgress > start - 0.1) {
          return 1; // Partially visible
        } else {
          return 0; // Slightly visible
        }
      });

      setOpacities((prevOpacities) => {
        if (JSON.stringify(prevOpacities) !== JSON.stringify(newOpacities)) {
          return newOpacities;
        }
        return prevOpacities;
      });
    };

    const unsubscribe = scrollYProgress.on("change", updateOpacities);
    updateOpacities(); // Initial opacity update

    return () => unsubscribe();
  }, [scrollYProgress, words]); // Include words here

  return (
    <div
      className="text-reveal-container"
      ref={containerRef}
      style={{
        height: "200vh",
        width: "100%",
        backgroundColor: "#121212",
        position: "relative",
        overflow: "clip",
        transform: isInView ? "none" : "translateY(100px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
      }}
    >
      <div
        style={{
          position: "absolute",
          inset: "0",
        }}
      >
        <motion.div
          className="text-reveal-background"
          style={{
            position: "sticky",
            top: "0%",
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${require("../../images/background-text-reveal.webp")})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            filter: "grayscale(1)",
          }}
        />
      </div>
      <motion.div
        className="text-reveal-overlay"
        style={{
          opacity,
          position: "absolute",
          inset: "0",
          backgroundColor: "var(--background-color)",
        }}
      />
      <div
        className="text-reveal-content"
        style={{
          position: "sticky",
          top: "50%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
          gap: ".5rem",
          padding: "2rem",
          margin: "0 auto 25% auto",
          maxWidth: "800px",
        }}
      >
        {words.map((word, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <motion.h1
              style={{
                color: "var(--tertiary-color)",
                opacity: opacities[index],
                transition: "opacity 0.25s ease-in-out",
              }}
            >
              {word}
            </motion.h1>
          </div>
        ))}
        <AnimatePresence>
          {showDescription && (
            <motion.p
              style={{ width: "100%", color: "var(--body-color)" }}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5,}}
            >
              {description}
            </motion.p>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default FadeText;
