import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./preloader.css";
import { useIsPhone } from "../../hooks/mediaQuery";

function Preloader() {
  const isPhone = useIsPhone();

  useEffect(() => {
    let preload = document.querySelector(".preloader");
    setTimeout(() => {
      preload.style.display = "none";
    }, 1000); // Adjust the timeout as needed
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="preloader"
        id="preloader"
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <motion.div
          className="spinner_wrap"
          initial={{
            scale: 1,
            opacity: 1,
            translateX: "-50%",
            translateY: "-50%",
          }}
          animate={{
            scale: isPhone ? 1.5 : 2,
            opacity: 1,
            translateX: "-50%",
            translateY: "-50%",
          }}
          exit={{ opacity: 0 }}
          transition={{
            scale: { duration: 1, ease: "easeOut" },
            opacity: { delay: 0.5, duration: 0.5, ease: "easeIn" },
            rotate: { duration: 1, ease: "easeOut" },
          }}
        >
          <div className="spinner" />
        </motion.div>
        <motion.div
          className="upper-div"
          initial={{ y: 0 }}
          animate={{ y: "-200%" }}
          exit={{ y: "-200%" }}
          transition={{ duration: 1.25 }}
        />
        <motion.div
          className="lower-div"
          initial={{ y: 0 }}
          animate={{ y: "200%" }}
          exit={{ y: "200%" }}
          transition={{ duration: 1.25 }}
        />
      </motion.div>
    </AnimatePresence>
  );
}

export default Preloader;
