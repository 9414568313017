import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence, LayoutGroup, useInView } from "framer-motion";
import "./gallery.css";

function Gallery(props) {
  const gallery = props.gallery;
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [zIndexMap, setZIndexMap] = useState({});
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const showImage = (imageIndex) => {
    const img = gallery[imageIndex];
    setSelectedImage(img);
    setActiveImageIndex(imageIndex);
    setZIndexMap((prev) => ({ ...prev, [imageIndex]: 1000 }));
  };

  const hideImage = () => {
    setSelectedImage(null);
    if (activeImageIndex !== null) {
      setTimeout(() => {
        resetZIndex(activeImageIndex);
        setActiveImageIndex(null);
      }, transitionSettings.duration * 1500);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("gallery-fullscreen-container")) {
        hideImage();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, );

  const transitionSettings = {
    duration: 0.5,
    ease: "easeInOut",
  };

  const resetZIndex = (imageIndex) => {
    setZIndexMap((prev) => ({ ...prev, [imageIndex]: 1 }));
  };

  return (
    <LayoutGroup>
      <div
        className="gallery"
        ref={ref}
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {gallery.map((image, imageIndex) => (
          <motion.div
            layoutId={`image-${image.id}`}
            key={image.id}
            className="gallery-image-container"
            style={{
              zIndex: zIndexMap[imageIndex] || 0,
            }}
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <motion.img
              className={["gallery-image", image.className].join(" ")}
              alt={image.alt}
              src={image.image}
              onClick={() => showImage(imageIndex)}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={transitionSettings}
            />
          </motion.div>
        ))}
      </div>

      <AnimatePresence>
        {selectedImage && (
          <motion.div
            className="gallery-fullscreen-container"
            onClick={hideImage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={transitionSettings}
          >
            <motion.img
              className="gallery-image-fullscreen"
              src={selectedImage.image}
              alt="Selected"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={transitionSettings}
              layoutId={`image-${selectedImage.id}`}
            />
            <button onClick={hideImage} className="btn btn-dark">
              Schließen
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </LayoutGroup>
  );
}

export default Gallery;
