import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./list.css";
import { listItems } from "../../data/DataDefault";
import Icon from "../../helpers/Icon"; 

function List(props) {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchText, setSearchText] = useState("");
  const items = listItems;

  const toggleCategory = (category) => {
    setSelectedCategories((prevState) =>
      prevState.includes(category)
        ? prevState.filter((cat) => cat !== category)
        : [...prevState, category]
    );
  };

  const toggleAllCategories = () => {
    if (
      selectedCategories.length === categories.length ||
      selectedCategories.length === 0
    ) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(categories);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const categories = [...new Set(items.map((item) => item.category))];

  const filteredItems = items
    .filter((item) => {
      const matchesCategory =
        selectedCategories.length === 0 ||
        selectedCategories.includes(item.category);
      const matchesSearch =
        searchText.length < 3 ||
        item.name.toLowerCase().includes(searchText.toLowerCase());
      return matchesCategory && matchesSearch;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const listVariants = {
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: { duration: 0.5, delay: i * 0.075 },
    }),
    exit: (i) => ({
      opacity: 0,
      x: -20,
      transition: { duration: 0.3, delay: i * 0.075 },
    }),
  };

  return (
    <div className="list-wrapper">
      <div className="list-container">
        <input
          className="list-search-input"
          type="text"
          placeholder="Leistung suchen"
          value={searchText}
          onChange={handleSearchChange}
        />
        <div className="filter-buttons-wrapper">
          <div className="filter-buttons-container">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => toggleCategory(category)}
                className={
                  selectedCategories.length === 0 ||
                  selectedCategories.includes(category)
                    ? "btn-dark"
                    : "btn-outline"
                }
              >
                {category}
              </button>
            ))}

            <button
              onClick={toggleAllCategories}
              className={
                selectedCategories.length === categories.length ||
                selectedCategories.length === 0
                  ? "btn-dark"
                  : "btn-outline"
              }
            >
              {selectedCategories.length === categories.length ||
              selectedCategories.length === 0
                ? "Alle"
                : "Zeige Alle"}
            </button>
          </div>
        </div>
        {filteredItems.length === 0 ? (
          <p>
            <Icon iconFa="fas" iconName="exclamation-triangle" /> {/* Custom Icon component */}
            Keine Ergebnisse gefunden.
          </p>
        ) : (
          <motion.ul
            className="list-items"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={listVariants}
          >
            <AnimatePresence>
              {filteredItems.map((item, index) => (
                <motion.li
                  key={item.id}
                  custom={index}
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className="list-item"
                >
                  <span className="list-item-details">
                    <span className="list-item-category">
                      <Icon iconFa={item.iconFa} iconName={item.iconName} /> {/* Custom Icon component */}
                    </span>
                    <span className="list-item-title">{item.name}</span>
                  </span>
                  <span className="list-item-price">{item.price}</span>
                </motion.li>
              ))}
            </AnimatePresence>
          </motion.ul>
        )}
      </div>
    </div>
  );
}

export default List;
