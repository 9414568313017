import React from "react";
import { Link, useLocation } from 'react-router-dom';
import { motion } from "framer-motion";
import "./totopbutton.css";
import Icon from "../../helpers/Icon"; // Ensure the correct path to Icon component

function ToTopButtonItems(props) {
  const { totopbuttonitems } = props;
  const tapAnimation = { scale: 0.95 };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div
      className="to-top-button-items-container"
      id="to-top-button-items-container"
    >
      <>
        {totopbuttonitems.map((item) => (
          <motion.div
            whileTap={tapAnimation}
            className="to-top-button-items"
            key={item.id}
          >
            {item.link && (
              <Link 
                to={item.link} 
                className={currentPath === "/" ? "greyed-out" : ""}
              >
                <Icon iconFa={item.iconFa} iconName={item.iconName} />
              </Link>
            )}
            {item.externallink && (
              <a href={item.externallink}>
                <Icon 
                  iconFa={item.iconFa} 
                  iconName={item.iconName} 
                  onClick={item.function ? scrollToTop : null} 
                />
              </a>
            )}
            {item.totopbutton && (
              <div className="scroll-up-button" onClick={item.function ? scrollToTop : null} >
                <Icon 
                  iconFa={item.iconFa} 
                  iconName={item.iconName} 
                />
              </div>
            )}
          </motion.div>
        ))}
      </>
    </div>
  );
}

export default ToTopButtonItems;
