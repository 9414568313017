export const DefaultUserform = [
  {
    firstname: 'true',
    lastname: 'true',
    darkmodeswitch: 'true',
    savepersonaldata: 'true',
    colorchoice: 'true',
    id: 1,
    section: 1,
  }
]

export const parallaxData = [
  {
    headline: 'Einzigartige Websites',
    headlineOverlay: 'Joshua Krieg',
    productImageUrl: require('../images/jk_logo_white.png'),
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    descriptionOverlay: 'Overlay - Beschreibung...',
    url: require('../images/hero-bg.webp'),
    id: 1
  },
]

export const endlessSliderData = [
  {
    url: require('../images/gallery-11-min.webp'),
    id: 1,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 2,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 3,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 4,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 5,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 6,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 7,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 8,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 9,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 10,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 11,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 12,
    section: 1
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 1,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 2,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 3,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 4,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 5,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 6,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 7,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 8,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 9,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 10,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 11,
    section: 2
  },
  {
    url: require('../images/gallery-11-min.webp'),
    id: 12,
    section: 2
  },

]


export const zoomInData = [
  {
    url: require('../images/gallery-11-min.webp'),
    id: 0,
    section: 1
  },
  {
    url: require('../images/gallery-3-min.webp'),
    id: 1,
    section: 1
  },
  {
    url: require('../images/gallery-4-min.webp'),
    id: 2,
    section: 1
  },
  {
    url: require('../images/gallery-6-min.webp'),
    id: 3,
    section: 1
  },
  {
    url: require('../images/gallery-8-min.webp'),
    id: 4,
    section: 1
  }

]

export const GalleryItems = [
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-1-min.webp'),
    id: 1,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-2-min.webp'),
    id: 2,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-3-min.webp'),
    id: 3,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-4-min.webp'),
    id: 4,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-5-min.webp'),
    id: 5,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-6-min.webp'),
    id: 6,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-7-min.webp'),
    id: 7,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-8-min.webp'),
    id: 8,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-9-min.webp'),
    id: 9,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-10-min.webp'),
    id: 10,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-11-min.webp'),
    id: 11,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-12-min.webp'),
    id: 12,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-13-min.webp'),
    id: 13,
  },
  {
    className: 'true',
    alt: 'Showcases - Joshua Krieg',
    description: 'true',
    image: require('../images/gallery-14-min.webp'),
    id: 14,
  }
]

export const ToTopButtonItemsDefault = [

  {
    id: 2,
    iconFa: 'fas',
    iconName: 'fa-home',
    function: false,
    link: '/'
  },
  {
    id: 1,
    iconFa: 'fas',
    iconName: 'fa-arrow-up',
    function: true,
    totopbutton: true,
  },
  {
    id: 3,
    iconFa: 'fas',
    iconName: 'fa-phone',
    function: false,
    externallink: 'tel:+01603465558'
  },
  {
    id: 4,
    iconFa: 'fas',
    iconName: 'fa-envelope',
    function: false,
    externallink: 'mailto:info@joshuakrieg.com'
  }
]

export const listItems = [
  { id: 4, name: "All Inclusive", category: "All Inclusive", price: "ab € 3999", iconFa: 'fas', iconName: 'fa-star' },
  { id: 8, name: "Webdesign", category: "Web", price: "ab € 1999", iconFa: 'fas', iconName: 'fa-laptop' },
  { id: 0, name: "Webshop", category: "Web", price: "ab € 2999", iconFa: 'fab', iconName: 'fa-shopify' },
  { id: 1, name: "Webentwicklung", category: "Web", price: "ab € 2499", iconFa: 'fas', iconName: 'fa-code' },
  { id: 2, name: "Corporate Design", category: "CI", price: "ab € 999", iconFa: 'fas', iconName: 'fa-font', },
  { id: 3, name: "SEO", category: "Web", price: "ab € 499", iconFa: 'fas', iconName: 'fa-magnifying-glass' },
  { id: 5, name: "Consulting Web", category: "Consulting", price: "ab € 999", iconFa: 'fas', iconName: 'fa-atom' },
  { id: 6, name: "Consulting App", category: "Consulting", price: "ab € 999", iconFa: 'fas', iconName: 'fa-atom' },
];


export const boxData = [
  {
    id: 1,
    className: "box half image-mask",
    imgpath: require('../images/gallery-1-min.webp'),
    imgClassName: "box-img-rounded",
    title: "Title",
    headlineClassname: "headline-class",
    description: "Some description",
    textClassname: "text-class",
    linkName: "Link Text",
    linkTarget: "/link-target",
    linkClassname: "btn-outline",
    linkNameIconFa: "fas",
    linkNameIconName: "fa-info",
    secondLinkName: "Second Link Text",
    secondLinkTarget: "/second-link-target",
    secondLinkNameIconFa: "fas",
    secondLinkNameIconName: "fa-info",
    thirdLinkName: "Third Link Text",
    thirdLinkTarget: "/third-link-target",
    thirdLinkNameIconFa: "fas",
    thirdLinkNameIconName: "fa-info",
  }
]

export const gridData = {
  items: [
    {
      name: "item-1",
      headline: "HTML",
      iconName: "fa-plus",
      content: <p>CSS wird verwendet, um das Design und Layout der Website zu gestalten. Mit CSS können die Darstellung von Elementen wie Farben, Schriftarten und Abständen präzise gesteuert werden. Dadurch wird das visuelle Erscheinungsbild der Website individuell angepasst, was für eine konsistente und ansprechende Benutzeroberfläche sorgt, unabhängig vom verwendeten Gerät oder Bildschirm.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
      descImage: require('../images/grid-item-1.webp'),
    },
    {
      name: "item-2",
      headline: "CSS",
      iconName: "fa-plus",
      content: <p>HTML wird genutzt, um die Struktur und den Inhalt der Seiten auf der Website zu definieren. HTML bildet das Fundament jeder Webseite, indem Texte, Bilder, Links und andere Elemente klar organisiert werden. Dadurch entsteht eine solide Basis, die es ermöglicht, Inhalte für verschiedene Geräte und Bildschirmgrößen zugänglich und benutzerfreundlich darzustellen.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
      descImage: require('../images/grid-item-2.webp'),
    },
    {
      name: "item-3",
      headline: "JavaScript",
      iconName: "fa-plus",
      content: <p>JavaScript wird auf der Website genutzt, um interaktive und dynamische Funktionen zu implementieren. Mit JavaScript lassen sich Elemente auf der Seite in Echtzeit verändern, Benutzereingaben verarbeiten und komplexe Animationen erstellen. Diese vielseitige Programmiersprache ermöglicht eine verbesserte Nutzererfahrung durch schnellere Reaktionen und eine nahtlose Interaktivität direkt im Browser.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
    },
    {
      name: "item-4",
      headline: "React",
      iconName: "fa-plus",
      content: <p>React wird eingesetzt, um eine reaktive und effiziente Benutzeroberfläche auf der Website zu schaffen. Mit React können Komponenten modular entwickelt und wiederverwendet werden, was die Entwicklung beschleunigt und die Codequalität verbessert. Diese moderne JavaScript-Bibliothek ermöglicht eine schnelle und dynamische Aktualisierung der Inhalte, wodurch die Nutzererfahrung insgesamt verbessert wird.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
    },
    {
      name: "item-5",
      headline: "Framer Motion",
      iconName: "fa-plus",
      content: <p>Framer Motion wird genutzt, um beeindruckende und flüssige Animationen auf der Website zu erstellen. Die Animationen werden direkt im Frontend verarbeitet, was eine datenschutzkonforme Implementierung ermöglicht. Durch die lokale Ausführung wird keine Verbindung zu externen Servern hergestellt, wodurch die Privatsphäre der Nutzer gewahrt bleibt und die Performance der Seite optimiert wird.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
    },
    {
      name: "item-6",
      headline: "",
      image: require('../images/gallery-4-min.webp'),
      style: { background: "var(--secondary-color)" },
    },
    {
      name: "item-7",
      headline: "Netlify",
      iconName: "fa-plus",
      content: <p>Netlify dient als zuverlässige und effiziente Plattform für das Deployment der Website. Die Inhalte werden auf sicheren Servern bereitgestellt, was den Datenschutz und die Performance gewährleistet. Durch die Nutzung von Netlify können Aktualisierungen schnell und unkompliziert durchgeführt werden, während gleichzeitig der Schutz der Nutzerdaten und eine hohe Verfügbarkeit sichergestellt sind.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
    },
    {
      name: "item-8",
      headline: "One.com",
      iconName: "fa-plus",
      content: <p>One.com-Dienste werden auf der Website genutzt, um eine zuverlässige Hosting-Lösung bereitzustellen. Alle Daten werden sicher auf europäischen Servern gespeichert, um den Anforderungen des Datenschutzes gerecht zu werden. Dies gewährleistet eine schnelle und sichere Bereitstellung der Inhalte, während die Privatsphäre der Nutzer umfassend geschützt bleibt.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
    }, 
    {
      name: "item-9",
      headline: "EMail JS",
      iconName: "fa-plus",
      content: <p>EmailJS wird genutzt, um das Senden von E-Mails direkt über das Frontend zu ermöglichen. Alle sensiblen Daten werden lokal verarbeitet und nur die notwendigen Informationen sicher an den E-Mail-Dienst weitergeleitet. Dies gewährleistet eine datenschutzkonforme Nutzung und schützt die Privatsphäre der Nutzer.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
    }, 
    {
      name: "item-10",
      headline: "Google Fonts",
      iconName: "fa-plus",
      content: <p>Google Fonts kommen auf der Website zum Einsatz, um eine ansprechende Typografie zu gewährleisten. Diese Schriften werden lokal eingebunden, um datenschutzkonform zu arbeiten. Dadurch werden keine Verbindungen zu externen Google-Servern hergestellt, was den Schutz der Nutzerdaten erhöht und gleichzeitig die Ladezeiten optimiert.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
    },
    {
      name: "item-11",
      headline: "Fontawesome",
      iconName: "fa-plus",
      content: <p>FontAwesome wird auf der Website genutzt, um ansprechende und vielseitige Icons darzustellen. Die Einbindung erfolgt lokal, um eine datenschutzkonforme Nutzung zu gewährleisten. Dadurch werden keine Daten an externe Server übertragen, was den Schutz der Privatsphäre der Nutzer erhöht und die Ladezeiten optimiert.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
    },
    {
      name: "item-12",
      headline: "Github",
      iconName: "fa-plus",
      content: <p>GitHub wird zur Verwaltung und Versionierung des Quellcodes der Website eingesetzt. Durch die zentrale Speicherung des Codes in einem privaten Repository wird eine strukturierte Entwicklung und sichere Zusammenarbeit gewährleistet. GitHub bietet Datenschutz und Kontrolle, da keine sensiblen Daten ungeschützt extern gespeichert werden und volle Kontrolle über den Zugriff besteht.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
    },
    {
      name: "item-13",
      headline: "Service Worker",
      iconName: "fa-plus",
      content: <p>Service Worker sorgen auf der Website für eine zuverlässige und schnelle Nutzererfahrung. Durch das lokale Caching von Ressourcen werden Ladezeiten verkürzt und die Offline-Nutzung unterstützt. Diese lokale Verarbeitung trägt zur Datensicherheit bei, da keine sensiblen Informationen an externe Server gesendet werden.</p>,
      style: { background: "var(--secondary-color)", padding: "1rem" },
    },
  ],
  layout: {
    areas: {
      default: `"item-1 item-1 item-1 item-1 item-2 item-2 item-2 item-2 item-3 item-3 item-3 item-3 item-3 item-3 item-3 item-3 item-4 item-4 item-4 item-4 item-4 item-4 item-4 item-4"
                "item-1 item-1 item-1 item-1 item-2 item-2 item-2 item-2 item-3 item-3 item-3 item-3 item-3 item-3 item-3 item-3 item-4 item-4 item-4 item-4 item-4 item-4 item-4 item-4"
                "item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7 item-7 item-8 item-8 item-8 item-8"
                "item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7 item-7 item-8 item-8 item-8 item-8"
                "item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7 item-7 item-8 item-8 item-8 item-8"
                "item-9 item-9 item-9 item-9 item-10 item-10 item-10 item-10 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7 item-7 item-8 item-8 item-8 item-8"
                "item-9 item-9 item-9 item-9 item-10 item-10 item-10 item-10 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7 item-7 item-8 item-8 item-8 item-8"
                "item-12 item-12 item-12 item-12 item-12 item-12 item-12 item-12 item-13 item-13 item-13 item-13 item-13 item-13 item-13 item-13 item-11 item-11 item-11 item-11 item-11 item-11 item-11 item-11"`,
      desktop: `"item-1 item-1 item-1 item-1 item-2 item-2 item-2 item-2 item-3 item-3 item-3 item-3 item-3 item-3 item-3 item-3 item-4 item-4 item-4 item-4 item-4 item-4 item-4 item-4"
                "item-1 item-1 item-1 item-1 item-2 item-2 item-2 item-2 item-3 item-3 item-3 item-3 item-3 item-3 item-3 item-3 item-4 item-4 item-4 item-4 item-4 item-4 item-4 item-4"
                "item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7 item-7 item-8 item-8 item-8 item-8"
                "item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7 item-7 item-8 item-8 item-8 item-8"
                "item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-5 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7 item-7 item-8 item-8 item-8 item-8"
                "item-9 item-9 item-9 item-9 item-10 item-10 item-10 item-10 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7 item-7 item-8 item-8 item-8 item-8"
                "item-9 item-9 item-9 item-9 item-10 item-10 item-10 item-10 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7 item-7 item-8 item-8 item-8 item-8"
                "item-12 item-12 item-12 item-12 item-12 item-12 item-12 item-12 item-13 item-13 item-13 item-13 item-13 item-13 item-13 item-13 item-11 item-11 item-11 item-11 item-11 item-11 item-11 item-11"`,
      tablet: `"item-1 item-1 item-1 item-1 item-2 item-2 item-2 item-2 item-3 item-3 item-3 item-3"
                "item-4 item-4 item-4 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7"
                "item-5 item-5 item-5 item-6 item-6 item-6 item-6 item-6 item-6 item-7 item-7 item-7"
                "item-8 item-8 item-9 item-9 item-9 item-9 item-9 item-10 item-10 item-10 item-12 item-12"
                "item-11 item-11 item-11 item-11 item-11 item-11 item-13 item-13 item-13 item-13 item-12 item-12"`, 
      mobile: `"item-1 item-1 item-1 item-2 item-2 item-2"
                "item-3 item-3 item-3 item-4 item-4 item-4"
                "item-5 item-5 item-5 item-4 item-4 item-4"
                "item-6 item-6 item-7 item-7 item-7 item-7"
                "item-6 item-6 item-7 item-7 item-7 item-7"
                "item-8 item-8 item-8 item-9 item-9 item-9"
                "item-10 item-10 item-10 item-10 item-10 item-10"
                "item-11 item-11 item-11 item-11 item-11 item-11"
                "item-12 item-12 item-12 item-13 item-13 item-13"`,
    },
    rows: "auto 1fr auto",
    columns: {
      desktop: "repeat(24, 1fr)",
      tablet: "repeat(12, 1fr)",
      mobile: "repeat(6, 1fr)",
    },
    gap: ".5rem",
  },
};


export const horizontalSections = [
  { headline: "Headline 1", backgroundColor: '#cdb4db', image: require('../images/gallery-4-min.webp')},
  { headline: "Headline 1", backgroundColor: '#ffc8dd', image: require('../images/gallery-4-min.webp')},
  { headline: "Headline 1", backgroundColor: '#ffafcc', image: require('../images/gallery-4-min.webp') },
  { headline: "Headline 1", backgroundColor: '#bde0fe', image: require('../images/gallery-4-min.webp') },
  { headline: "Headline 1", backgroundColor: '#a2d2ff', image: require('../images/gallery-4-min.webp') },
];