import React, { useState, useRef, useEffect } from "react";
import { motion, useInView } from "framer-motion";
import "./usercard.css";
import Icon from "../../helpers/Icon";

function Usercard(props) {
  const usercard = props.usercard;
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const handleOutsideClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const bodyVariants = {
    hidden: { opacity: 1, height: "2rem", overflow: "hidden" },
    visible: { opacity: 1, height: "auto" },
  };

  const imageHoverVariants = {
    hover: {
      scale: [1, 1.05, 1],
      transition: {
        duration: 1.5,
        repeat: Infinity,
        repeatType: "loop",
        ease: "easeInOut",
      },
    },
  };

  return (
    <>
      {usercard.map((usercard) => (
        <motion.div
          key={usercard.id}
          style={{
            transform: isInView ? "none" : "translateY(100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
          className="usercard-container primary"
          ref={ref}
          onClick={() => setIsVisible(!isVisible)}
        >
          <div className="usercard-image">
            <motion.img
              initial={{ x: 0 }}
              animate={!isVisible ? "hover" : null}
              transition={{ duration: 0.5 }}
              alt="Joshua Krieg"
              variants={imageHoverVariants}
              src={usercard.image}
            />
          </div>
          <motion.div
            initial="hidden"
            animate={isVisible ? "visible" : "hidden"}
            variants={bodyVariants}
            transition={{ duration: 0.5 }}
            className="usercard-body"
          >
            <h3 className="usercard-title">
              {usercard.title}{" "}
              {isVisible ? (
                <motion.div
                  key="minus"
                  initial={{ rotate: 45 }}
                  animate={{ rotate: 0 }}
                  exit={{ rotate: 45 }}
                  transition={{ duration: 0.1 }}
                >
                  <Icon iconFa="fas" iconName="fa-minus" />
                </motion.div>
              ) : (
                <motion.div
                  key="plus"
                  initial={{ rotate: 45 }}
                  animate={{ rotate: 0 }}
                  exit={{ rotate: 45 }}
                  transition={{ duration: 0.1 }}
                >
                  <Icon iconFa="fas" iconName="fa-plus" />
                </motion.div>
              )}
            </h3>
            <motion.p
              initial="hidden"
              animate={isVisible ? "visible" : "hidden"}
              variants={bodyVariants}
              transition={{ duration: 0.25 }}
              dangerouslySetInnerHTML={{ __html: usercard.description }}
            />
            <motion.p
              initial="hidden"
              animate={isVisible ? "visible" : "hidden"}
              variants={bodyVariants}
              transition={{ duration: 0.5 }}
            >
              {usercard.subtext}
            </motion.p>
            <motion.p
              initial="hidden"
              animate={isVisible ? "visible" : "hidden"}
              variants={bodyVariants}
              transition={{ duration: 0.25 }}
              dangerouslySetInnerHTML={{ __html: usercard.personalinfo }}
            />
          </motion.div>
        </motion.div>
      ))}
    </>
  );
}

export default Usercard;
