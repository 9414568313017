import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Icon from "../../helpers/Icon";
import "./darkmode.css";

const setDark = () => {
  localStorage.setItem("theme", "dark");
  document.documentElement.setAttribute("data-theme", "dark");
};

const storedTheme = localStorage.getItem("theme");

const prefersDark =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

const defaultDark =
  storedTheme === "dark" || (storedTheme === null && prefersDark);

if (defaultDark) {
  setDark();
}

function DarkMode() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const tapAnimation = { scale: 0.95 };
  
  useEffect(() => {
    const handleCustomColors = () => {
      const currentTheme = localStorage.getItem("theme");
      if (currentTheme === "light") {
        setTheme("light");
      } else if (currentTheme === "dark") {
        setTheme("dark");
      }
    };

    window.addEventListener("custom-colors", handleCustomColors);

    return () => {
      window.removeEventListener("custom-colors", handleCustomColors);
    };
  }, []);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    setTimeout(() => {
      window.dispatchEvent(new Event("storage"));
    }, 50);
  };

  useEffect(() => {
    if (theme === "light") {
      localStorage.setItem("theme", "light");
      document.documentElement.removeAttribute("data-theme");
    } else if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      document.documentElement.setAttribute("data-theme", "dark");
    }
    localStorage.setItem("theme", theme);
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const icon = (
    <motion.div
      animate={{ rotate: theme === "light" ? 0 : 180 }}
      transition={{ duration: 0.05 }}
      whileTap={tapAnimation}
    >
      <Icon iconFa="fas" iconName="circle-half-stroke" />
    </motion.div>
  );

  return (
    <button id="darkmode-toggle" onClick={toggleTheme}>
      {icon}
    </button>
  );
}

export default DarkMode;
