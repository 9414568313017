import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import Icon from "../../helpers/Icon";

function NavigationButtons(props) {
  const navigationbuttons = props.navigationbuttons;
  const tapAnimation = { scale: 0.95 };

  return (
    <>
      {navigationbuttons.map((navigationbutton) =>
        navigationbutton.internalLink ? (
          <motion.div
            key={navigationbutton.id}
            whileTap={tapAnimation}
            style={{ display: "inline-block" }}
          >
            <NavLink
              to={navigationbutton.internalLink}
              className={navigationbutton.className}
              onClick={
                navigationbutton.onClick
                  ? (e) => {
                      e.preventDefault(); // Prevent default action if onClick is defined
                      navigationbutton.onClick();
                    }
                  : undefined
              }
            >
              {navigationbutton.iconName && (
                <Icon
                  iconFa={navigationbutton.iconFa} // or other prefix depending on your icons
                  iconName={navigationbutton.iconName} // Remove 'fa-' prefix
                />
              )}
              {navigationbutton.title && (
                <span className="bold">{navigationbutton.title}</span>
              )}
            </NavLink>
          </motion.div>
        ) : (
          <motion.a
            whileTap={tapAnimation}
            key={navigationbutton.id}
            target={navigationbutton.target}
            href={navigationbutton.link}
            className={navigationbutton.className}
            onClick={
              navigationbutton.onClick
                ? (e) => {
                    e.preventDefault(); // Prevent default action if onClick is defined
                    navigationbutton.onClick();
                  }
                : undefined
            }
          >
            {navigationbutton.iconName && (
              <Icon
                iconFa={navigationbutton.iconName} // or other prefix depending on your icons
                iconName={navigationbutton.iconName} // Remove 'fa-' prefix
              />
            )}
            {navigationbutton.title && (
              <span className="bold">{navigationbutton.title}</span>
            )}
          </motion.a>
        )
      )}
    </>
  );
}

export default NavigationButtons;
