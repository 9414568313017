import React, { useState, useEffect, useRef } from "react";
import { ToTopButtonItemsDefault } from "../../data/DataDefault";
import "./totopbutton.css";
import ToTopButtonItems from "./ToTopButtonItems";
import useLoginStatus from "../../hooks/loginCheck";
import { AnimatePresence, easeInOut, motion } from "framer-motion";
import Icon from "../../helpers/Icon";

const ToTopButton = () => {
  const [visible, setVisible] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const toTopRef = useRef();
  const totopbuttonitems = ToTopButtonItemsDefault;
  const isLoggedIn = useLoginStatus();
  const tapAnimation = { scale: 0.95 };

  const handleOutsideClick = (e) => {
    if (toTopRef.current && !toTopRef.current.contains(e.target)) {
      setShowButtons(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
      setShowButtons(false);
    }
  };

  const toggleButton = () => {
    setShowButtons(!showButtons);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  return (
    <>
      {isLoggedIn && (
        <div
          ref={toTopRef}
          className={!showButtons ? "to-top-button initial" : "to-top-button"}
        >
          <AnimatePresence>
            {visible && (
              <motion.button
                key="to-top-button"
                onClick={toggleButton}
                className="btn-to-top"
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 100, opacity: 0 }}
                transition={{ duration: 0.5, ease: easeInOut }}
              >
                <motion.div whileTap={tapAnimation}>
                  <Icon
                    iconFa="fas"
                    iconName={showButtons ? "fa-times" : "fa-ellipsis-h"}
                  />
                </motion.div>
              </motion.button>
            )}
            {showButtons && (
              <motion.div
                key="to-top-button-items"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.125, ease: easeInOut }}
              >
                <ToTopButtonItems totopbuttonitems={totopbuttonitems} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default ToTopButton;
