import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import "./toast.css";
import Icon from "../../helpers/Icon";

const Toast = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [shake, setShake] = useState(false);
  const { message, duration, link, iconName, iconFa } = props;

  const showToast = () => {
    const lastShown = localStorage.getItem("toastTimestamp");
    const now = new Date().getTime();

    if (!lastShown || now - lastShown > 24 * 60 * 60 * 1000) {
      setVisible(true);
      localStorage.setItem("toastTimestamp", now);
    }
  };

  useEffect(() => {
    let timer, hideTimer;

    if (duration) {
      timer = setTimeout(() => {
        showToast();
      }, duration);

      hideTimer = setTimeout(() => {
        setVisible(false);
      }, duration + 5000); // 5 seconds after showing
    }

    return () => {
      if (timer) clearTimeout(timer);
      if (hideTimer) clearTimeout(hideTimer);
    };
  }, [duration]);

  useEffect(() => {
    if (visible) {
      const shakeTimer = setTimeout(() => {
        setShake(true);
      }, 500); // Start shake after slide-in

      return () => clearTimeout(shakeTimer);
    }
  }, [visible]);

  useImperativeHandle(ref, () => ({
    triggerToast: () => {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 5000); // Show for 5 seconds
    }
  }));

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className="toast"
          initial={{ opacity: 0, x: "-200%" }}
          animate={{ opacity: 1, x: "-50%" }}
          exit={{ opacity: 0, x: "100%" }}
          transition={{ duration: 0.5 }}
        >
          <Link to={link} className="toast-link btn-highlight">
            <motion.div
              animate={shake ? { rotate: [0, -2, 2, -2, 2, -2, 0], scale: [1, 1.1, 1] } : {}}
              transition={{
                rotate: {
                  delay: 0.5, // Small delay to ensure shake starts after slide-in
                  duration: 0.5, // Duration of the shake animation
                  ease: "easeInOut", // Easing function for the shake animation
                },
                scale: {
                  delay: 1, // Delay to start enlargement after the shake
                  duration: 0.6, // Duration of the enlargement animation
                  ease: "easeInOut", // Easing function for the enlargement animation
                },
              }}
            >
              <Icon iconFa={iconFa} iconName={iconName} /> {message}
            </motion.div>
          </Link>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

export default Toast;
